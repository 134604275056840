<template>
<section class="edit-category">
    <Loader :loading="showLoader" />
    <div class="row">
        <div class="col-lg-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-title">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="page-title float-left">Add Seo</h3>
                            <router-link class="float-right" to="/website-management/seo">
                                <i class="mdi mdi-window-close font-size-18 text-dark"></i>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="card">
                <div class="card-body">
                    <form class="pt-2">
                        <div class="row">
                            <div class="col-md-5">
                                <div class="form-group">
                                    <label for="name">Url</label>
                                    <input type="text" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="name">Meta Title</label>
                                    <input type="text" class="form-control" />
                                </div>
                                <div class="form-group">
                                    <label for="name">Meta keyword</label>
                                    <input type="text" class="form-control" />
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="form-group">
                                    <label for="content">Meta Description</label>
                                    <vue-editor id="content" name="content" use-custom-image-handler></vue-editor>
                                </div>
                            </div>
                        </div>
                       

                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group float-right mb-0 pt-3">

                                    <button class="btn btn btn-add btn-primary">Submit</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>
</template>

<script>
const mediaApiURL = process.env.VUE_APP_MEDIA_SERVICE;
import vue2Dropzone from "vue2-dropzone";
import {
    VueEditor
} from "vue2-editor";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import Loader from "../../../components/Loader";
export default {
    name: "add-invoice",
    components: {
        Loader,
        VueEditor,
        vueDropzone: vue2Dropzone,
    },
    data() {
        return {
            showLoader: false,
            invoice: {
                name: "",
                active: true,
            },
            options: [],
            dropzoneOptions: {
                url: `${mediaApiURL}/file/upload`,
                maxFilesize: 10,
            },
            submitted: false,
            informations: [{
                title: '',
                subTitle: ''
            }],
            customerService: [{
                title: '',
                subTitle: ''
            }],
            myAccount: [{
                title: '',
                subTitle: ''
            }],
            socialMedia: [{
                icon: '',
                subTitle: ''
            }],
        };
    },
    mounted() {},
    methods: {
        addInformation() {
            this.informations.push({
                title: '',
                subTitle: ''
            });
        },
        removeInformation(index) {
            if (this.informations.length > 1) {
                this.informations.splice(index, 1);
            } else {
                alert('At least one Information is required.');
            }
        },

        addCustomerService() {
            this.customerService.push({
                title: '',
                subTitle: ''
            });
        },
        removeCustomerService(index) {
            if (this.customerService.length > 1) {
                this.customerService.splice(index, 1);
            } else {
                alert('At least one customer Service is required.');
            }
        },

        addMyAccount() {
            this.myAccount.push({
                title: '',
                subTitle: ''
            });
        },
        removeMyAccount(index) {
            if (this.myAccount.length > 1) {
                this.myAccount.splice(index, 1);
            } else {
                alert('At least one my Account Service is required.');
            }
        },

        addSocialMedia() {
            this.socialMedia.push({
                icon: '',
                subTitle: ''
            });
        },
        removeSocialMedia(index) {
            if (this.socialMedia.length > 1) {
                this.socialMedia.splice(index, 1);
            } else {
                alert('At least one Social Media Service is required.');
            }
        }
    },
};
</script>

<style scoped>
 
.ql-editor {
    min-height: 108px !important;
}
.btn-add-sub {
    padding: 10px !important;
    margin: 7px 0px 0px 7px;
    border-radius: 2px;

}
</style>
